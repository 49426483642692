<template>
    <div>
        <img src="@/assets/solutionpdf/alcohol_1.jpg" alt="">
        <img src="@/assets/solutionpdf/alcohol_2.jpg" alt="">
        <img src="@/assets/solutionpdf/alcohol_3.jpg" alt="">
        <img src="@/assets/solutionpdf/alcohol_4.jpg" alt="">
        <img src="@/assets/solutionpdf/alcohol_5.jpg" alt="">
    </div>
</template>

<script>
export default {
    name: "alcohol-solution"
}
</script>

<style scoped>
img{
    width: 100%;
}
</style>